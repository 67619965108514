<template>
  <div
    class="title-with-label"
    :class="[themeColorClass, { 'title-with-label--cols': checkboxLogo }, alignmentClass, addBorderForWhiteBgr]"
    :style="cssAlignment"
  >
    <UiLabel :label="label" />

    <UiHeader
      :header="header"
      :header-layout="headerLayout"
      :header-position="headerPosition"
      :header-size="headerSize"
    />

    <T3HtmlParser
      v-if="bodytext"
      class="title-with-label__text"
      :content="bodytext"
    />

    <div
      v-if="subheader"
      class="title-with-label__extra"
      :class="extraVariantClass"
    >
      <IconLightbulb />
      <span v-text="subheader" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import IconLightbulb from '~ui/assets/new-icons/icon-lightbulb.svg'
import { UiHeader, UiLabel } from '~ui/components'
import useHeaderAlignment from '~ui/composables/useHeaderAlignment'
import useTheming from '~ui/composables/useTheming'
import type { UiCeTextProps } from '~ui/types'

const props = defineProps<UiCeTextProps>()

const extraVariantClass = useTheming(props.themeColor, 'variant')

const themeColorClass = useTheming(props.themeColor)
const cssAlignment = useHeaderAlignment(props)

const alignmentClass = computed(() => {
  return `title-with-label--${props.headerPosition || 'left'}`
})

const addBorderForWhiteBgr = computed(() => {
  return props?.appearance?.color === 'white' && props.headerPosition === ''
    ? 'title-with-label--border-grey'
    : ''
})
</script>

<style lang="scss">
.title-with-label {
  display: grid;
  grid-template-columns: repeat(13, 1fr);
  padding-block: rem(8px) rem(16px);

  & .ui-label {
    grid-column: 1 / -1;

    width: 100%;
    color: var(--color__font-color--subheading);

    & + * {
      margin-top: rem(16px);
    }
  }

  & .ui-header {
    grid-column: 1 / -1;

    & + * {
      margin-top: rem(32px);
    }

    &__title {
      font-weight: 600;
      margin-bottom: 0;
    }
  }

  &__text {
    grid-column: 1 / -1;
    color: var(--color__font-color--text);
    text-align: var(--alignment);

    & a {
      color: var(--variant__theme-color, color(primary));
      text-decoration: underline;

      &:is(:hover, :active, :focus) {
        text-decoration: none;
      }
    }

    & > *:last-child {
      margin-bottom: 0;
    }

    & + * {
      margin-top: rem(32px);
    }
  }

  &__extra {
    grid-column: 1 / -1;
    display: flex;
    gap: rem(12px);
    font-size: rem(18px);
    position: relative;
    overflow: hidden;
    isolation: isolate;
    color: var(--variant__font-color);
    border-radius: em(4px);
    padding: rem(16px);

    &::before {
      content: '';
      position: absolute;
      inset: 0;
      z-index: z-index(below);
      opacity: 0.5;
      background-color: var(--variant__gradient-300);
    }

    @include media-query(max sm) {
      flex-direction: column;
    }

    & svg {
      flex-shrink: 0;

      & path {
        fill: var(--variant__theme-color);
      }
    }
  }

  &--left {
    padding-left: rem(20px);
    border-left: 1px solid var(--color__border-color);

    & .ui-label {
      border-right: 1px solid var(--color__border-color);
    }
  }

  &--center .ui-label {
    border-right: 1px solid var(--color__border-color);

    & + * {
      margin-top: rem(24px);
    }
  }

  &--right {
    padding-right: rem(20px);
    border-right: 1px solid var(--color__border-color);

    & .ui-label {
      border-left: 1px solid var(--color__border-color);

      &__title {
        margin-left: auto;
      }
    }
  }

  &--border-grey {
    border-left: 1px solid color(grey-250);
  }

  &--cols {
    padding-left: 0;
    border-left: none;
  }

  @include media-query(max sm) {
    padding-left: 0;
    border-left: none;
  }

  @include media-query(sm) {
    &--cols .ui-label,
    &--cols .ui-header {
      grid-column: 1 / 8;
    }

    &--cols &__text {
      grid-column: 8 / -1;
      padding-top: rem(56px);
      margin-top: 0;
    }
  }
}
</style>
